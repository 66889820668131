.App {
  background: black;
} 

.typewriter{
  margin-top: auto;
  font-family: 'Cutive Mono', monospace;
  font-size: 7vmax;
  line-height: 7vmax;
  color: white;
  text-shadow: 0px 0px 10px white;
  height: 12vmax;
}

.me-paragraph{
  font-family: 'Cormorant Garamond', serif;
  font-size: 25px;
  color: white;
  margin-right: 10%;
}

.header-container {
  height: 20vh;
  width: 100%;
}

.project {
  border: solid 5px white;
  height: auto;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
  position: relative;
  color: white;
  padding: 1px 0 1px 0;
  box-shadow: 0px 0px 15px white;
}

.links {
  bottom: 0;
  display: flex;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 5%;
}

.projects-container {
  padding-left: 5%;
  padding-right: 2%;
  display: flex;
  flex-wrap: wrap;
}

.projects-container h2 {
  text-align: center;
  font-size: 28px;
}

.projects-container p {
  font-size: 20px;
  text-align: center;
}

.project-tech-list {
  margin-left: 5%;
  display: block;
  flex-wrap: wrap;
  overflow: visible;
  word-break: break-all;
  margin-bottom: 90px;
}

.projects-container span {
  font-family: 'Cutive Mono', monospace;
  font-size: 18px;
  height: auto;
  display: inline-block;
  flex-wrap: wrap;
  color: white;
  border: solid 1px white;
  border-radius: 5px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 1px 10px 1px 10px;
}

.projects-header {
  text-align: center;
  font-size: 50px;
  margin-top: 5%;
  margin-bottom: 5%;
  color: white;
  text-shadow: 0px 0px 7px white;
}

.description {
  font-family: 'Amiri', serif;
  height: auto;
  margin-bottom: 12%;
  text-align: center;
  margin-left: 4.5%;
  margin-right: 4.5%;
}

.description img {
  padding-top: 10px;
  /* justify-content: center; */
  height: 200px;
  width: 275px;
}

.about {
  margin-top: 5%;
  margin-left: 5%;
}

.about-me-container{
  margin-left: 6%;
}

.connected {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 5%;
  color: white;
  text-shadow: 0px 0px 10px white;
  font-size: 30px;
}

.navigation-bar {
  display: flex;
  height: 12vh;
  width: auto;
  justify-content: space-evenly;
  align-items: center;
}

.navigation-bar a {
  display: inline-block;
}

@media only screen and (min-width: 500px) {
  .typewriter {
    /* font-size: 115px; */
    /* height: none; <-- this is currently not working */
  }
  .about {
    display: flex;
  }
  .project {
    width: 20vw;
    margin-left: 1%;
    margin-right: 1%;
    position: relative;
  }
  .links {
    position: absolute;
    justify-content: space-evenly;
  }
  .description img{
    padding-top: 5%;
    min-height: 20vh; 
    min-width: 10vw;
  }
}